import { gql } from "@apollo/client";

export type EmailTemplatePropType = {
    ID: number;
    TemplateName: string;
    TemplateIdentifier: string;
    Archived: boolean;
    TemplateModeID: number;
    EmailBody: string;
    EmailSubject: string;
    PreviewText: string;
    EmailTemplateObjectIDs: number[];
    FromUserID: number;
    IsInviteTemplate: boolean;
    IsMasterTemplate: boolean;
    EmailTemplateObjects: {
      Prefix: string;
      ModelType: string;
    }[]
    FromUser: {
      Value?: {
        Firstname: string;
        Surname: string;
      }
    }
    TemplateMode: {
        Value?: {
            Description: string;
        }
    }
    MultipleSubjects: boolean;
    EmailTemplateAttachments?: Array<{
      Filename: string;
      OriginalFileName: string;
    }>
    EmailTemplateSubjects: Array<{
      EmailSubject: string;
    }>

}

export const EmailTemplatesGridQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $order: [EmailTemplateSortInput!],
    $where: EmailTemplateFilterInput
  ) {
    grid: EmailTemplates(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      totalCount
      items {
        ID
        TemplateName
        TemplateIdentifier
        Archived
        IsMasterTemplate
        IsInviteTemplate
        PreviewText
        FromUser {
          Value {
            Firstname
            Surname
          }
        }
        TemplateMode {
            Value {
                Description
            }
        }
      }
    }
  }
`;

export const EmailTemplateScreenQuery = gql`
query(
    $id: Int!
  ) {
    screen: EmailTemplate(
      iD: $id
    ) {
        ID
        TemplateName
        TemplateIdentifier
        TemplateModeID
        EmailBody
        EmailSubject
        PreviewText
        EmailTemplateObjectIDs
        EmailTemplateObjects {
          Prefix
          ModelType
        }
        FromUserID
        IsInviteTemplate
        IsMasterTemplate
        Archived
        MultipleSubjects
        EmailTemplateAttachments {
          Filename
          OriginalFileName
        }
        EmailTemplateSubjects {
          EmailSubject
        }
    }
}` 

export const TemplateModesDropdownQuery = gql`
query {
  dropdown: TemplateModes {
    items {
      value: ID
      label: Description
    }
  }
}
`