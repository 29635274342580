import { useAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAPI"
import React, { useEffect, useRef, useState } from 'react'
import cryBaby from "@images/cry-baby.jpg"
import './index.scss'
import classNames from "classnames"
import branding from "@config/branding"
import Button from "@whitecobalt/tungsten/esm/components/Button"

interface UnsubscribeProps {

}

const Unsubscribe: React.FunctionComponent<UnsubscribeProps> = (props) => {
    const request = useAPI<{ success: boolean; message: string }>('/api/EmailCampaign/unsubscribe')

    const handleUnsubscribe = () => {
        const params = new URLSearchParams(window.location.search)

        request.call({
            data: {
                t: params.get('t') || null,
                r: params.get('r') || null,
                token: params.get('token') || null,
            }
        })
    }

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {   
        const container = ref.current;
        const img = container?.querySelector<HTMLImageElement>('.baby-img');
        const btn = container?.querySelector('.btn-unsubscribe');
    
        const handleMouseMove = (e: MouseEvent) => {
            if (!container || !btn || !img) return;
    
            const btnRect = btn.getBoundingClientRect();
    
            const btnCenterX = btnRect.left + btnRect.width / 2;
            const btnCenterY = btnRect.top + btnRect.height / 2;
    
            const distX = e.clientX - btnCenterX;
            const distY = e.clientY - btnCenterY;
            const distToButton = Math.sqrt(distX * distX + distY * distY);
    
            const maxInteractiveDist = Math.max(btnRect.width, btnRect.height) * 3;
    
            const proximityPercent = distToButton <= maxInteractiveDist 
                ? Math.max(0, 100 - (distToButton / maxInteractiveDist) * 100) 
                : 0;
    
            if (img) {
                img.style.opacity = `${proximityPercent / 100}`;
            }
        };

        const handleMouseOut = (e: MouseEvent) => {
            if (img && e.target !== btn) {
                img.style.opacity = '0';
            }
        };

        container?.addEventListener('mouseout', handleMouseOut);
    
        container?.addEventListener('mousemove', handleMouseMove);
    
        return () => {
            container?.removeEventListener('mouseout', handleMouseOut);

            container?.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    
    const [end, setEnd] = useState(false);

    return (
        <div 
            className={classNames("unsubscribe-main", { success: request.response?.success === true && !end })} 
            onAnimationEnd={() => {
                setTimeout(() => {
                    setEnd(true);
                }, 1000);
            }} 
            style={{backgroundImage: `url(${cryBaby})`}}
        >
            <div ref={ref} className="unsubscribe-container text-center py-20">
                {request.response?.success === true ? (
                    <div className="bg-white" style={{width: 400}}>
                        <img src={branding.logo.navbar} alt="Logo" className="mb-10" style={{width: 200}} />
                        <p>{request.response?.message}</p>
                    </div>
                ) : (
                    <>
                        <img src={cryBaby} alt="Baby" className={classNames("baby-img", { 'opacity-100': request.loading })} id="babyImage"/>
                        <div className="bg-white">
                            <img src={branding.logo.navbar} alt="Logo" className="mb-10" style={{width: 200}} />
                            <h1 className="">Was it something we said?</h1>
                            <p>We know your inbox fills up faster than a pub on Friday night, and we truly hope our emails weren’t the noisy neighbour. But if it’s time to part ways, just click the button below and we'll stop sending you anything else—no guilt trips, we promise.</p>
                            <div className="d-flex justify-content-center">
                                <Button 
                                    className="btn btn-primary btn-unsubscribe"
                                    loading={request.loading}
                                    onClick={handleUnsubscribe}
                                >
                                    Unsubscribe
                                </Button>
                            </div>
                            {request.response?.success === false && (
                                <div className="alert alert-danger mt-5 position-static" role="alert">
                                    {request.response?.message}
                                </div>
                            )}
                            <p className="footer-note">If you ever change your mind and want to reconnect, you can always find the resubscribe link in the footer of our website. We’ll be here, ready with more updates, tips, and cake!</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Unsubscribe