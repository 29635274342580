import { lazy } from "react";
// default Tungsten Pages
export const AvailableTagGroups = lazy(() => import('./AvailableTagGroups'))
export const AvailableTags = lazy(() => import('./AvailableTags'))
export const RegisteredUsers = lazy(() => import('./RegisteredUsers'))
export const Users = lazy(() => import('./Users'))
export const SentEmails = lazy(() => import('./SentEmails'))
export const SentSMS = lazy(() => import('./SentSMS'))
export const SystemSettings = lazy(() => import('./SystemSettings'))
export const Companies = lazy(() => import('./Companies'))
export const Reports = lazy(() => import('./Reports'))
export const Dashboard = lazy(() => import('./Dashboard'))
export const Widgets = lazy(() => import('./Widgets'))
export const Invoices = lazy(() => import('./Invoices'))
export const Bills = lazy(() => import('./Bills'))
export const PermissionPreset = lazy(() => import('./PermissionPresets'))
export const EmailTemplates = lazy(() => import('./EmailTemplates'))
export const SMSTemplates = lazy(() => import('./SMSTemplates'))
export const DocumentTemplates = lazy(() => import('./DocumentTemplates'))
export const PaymentAccounts = lazy(() => import('./PaymentAccounts'))
export const PaymentRuns = lazy(() => import('./PaymentRuns'))
export const TimeLogs = lazy(() => import('./TimeLogs'))
export const TimeLogHistory = lazy(() => import('./TimeLogHistory'))
export const TimeLogReport = lazy(() => import('./TimeLogReport'))
export const BackgroundProcesses = lazy(() => import('./BackgroundProcesses'))
export const DisconnectedProcesses = lazy(() => import('./DisconnectedProcesses'))
export const Budget = lazy(() => import('./Budget'))
export const EmailInbox = lazy(() => import('./EmailInbox'))
export const Wiki = lazy(() => import('./Wiki'))
export const DataImports = lazy(() => import('./DataImports'))


// customize Pages
export const ConfirmDebit = lazy(() => import('./CustomPages/ConfirmDebit'))
export const EmailResponses = lazy(() => import('./CustomPages/EmailResponses'))
export const EmployeeLeaves = lazy(() => import('./CustomPages/EmployeeLeaves'))
export const OrganisationalChart = lazy(() => import('./CustomPages/OrganisationalChart'))
export const BusinessProcessDesigner = lazy(() => import('./CustomPages/BusinessProcessDesigner'))
export const PositionAgreements = lazy(() => import('./CustomPages/PositionAgreements'))
export const Departments = lazy(() => import('./CustomPages/Departments'))
export const Customers = lazy(() => import('./CustomPages/Customers'))
export const MyCompany = lazy(() => import('./CustomPages/MyCompany'))
export const CRMGroups = lazy(() => import('./CustomPages/CompanyGroups'))
export const CRMIntroducers = lazy(() => import('./CustomPages/Introducers'))
export const CRMCompanies = lazy(() => import('./CustomPages/CRM'))
export const SentryDashboard = lazy(() => import('./CustomPages/SentryDashboard'))
export const AutoEmailCampaigns = lazy(() => import('./CustomPages/AutoEmailCampaigns'))
export const CompanyStandards = lazy(() => import('./CustomPages/CompanyStandards'))
export const EmailCampaigns = lazy(() => import('./CustomPages/EmailCampaigns'))
export const Candidates = lazy(() => import('./CustomPages/Candidates'))
export const Roles = lazy(() => import('./CustomPages/Roles'))
export const Contracts = lazy(() => import('./CustomPages/Contracts'))
export const CMSPages = lazy(() => import('./CMS/Pages'))
export const CMSBlogs = lazy(() => import('./CMS/Blogs'))
export const CMSNavigation = lazy(() => import('./CMS/Navigation'))
export const CMSFAQs = lazy(() => import('./CMS/FAQs'))
export const CMSRedirects = lazy(() => import('./CMS/Redirects'))
export const MyTasks = lazy(() => import('./CustomPages/MyTasks'))
export const Tasks = lazy(() => import('./CustomPages/Tasks'))
export const Projects = lazy(() => import('./CustomPages/Projects'))
export const CustomerInvoices = lazy(() => import('./CustomPages/CustomerInvoices'))
export const TeamMembers = lazy(() => import('./CustomPages/TeamMembers'))
export const Employees = lazy(() => import('./CustomPages/Employees'))
