import { gql } from "@apollo/client";


export type DropdownCommonType<P = Record<string, any>> = {
  value: number;
  label: string;
} & P
export type DropdownQueryType<P = DropdownCommonType> = {
  dropdown: {
    items: P[];
  };
}

export const AvailableTagsDropdownQuery = gql`
  query AvailableTagsDropdownQuery($where: AvailableTagFilterInput) {
    dropdown: AvailableTags(where: $where) {
      items {
        value: Tag
        label: Tag
        TagColour
        ReversedText
      }
    }
  }
`;

export const AvailableTagGroupsDropdownQuery = gql`
query AvailableTagGroupsDropdownQuery(
    $where: AvailableTagGroupFilterInput
) {
    dropdown: AvailableTagGroups(where: $where) {
        totalCount
        items {
            value: ID
            label: Description
        }
    }
}
`;

export const FontAwesomeIconsDropdownQuery = gql`
query($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query {
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query {
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query {
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query {
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query {
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query {
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;


export const ApplicationAreasDropdownQuery = gql`
  query {
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query {  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const EmailTemplatePresetsDropdownQuery = gql`
  query($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
        PreviewText
        EmailBody
        EmailSubject
        PrivateToUserID
        EmailTemplateAttachments {
          Filename
          OriginalFileName
        }
      }
    }
  }
`;

export const GendersDropdownQuery = gql`
  query($where: GenderFilterInput) {  
    dropdown: Genders(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ChatGPTContextsDropdownQuery= gql`
query ChatGPTContextsDropdownQuery($where: ChatGPTContextFilterInput) {  
  dropdown: ChatGPTContexts(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;

export const ChatGPTSubContextsDropdownQuery= gql`
query ChatGPTSubContextsDropdownQuery($where: ChatGPTSubContextFilterInput) {  
  dropdown: ChatGPTSubContexts(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;

export const ChatGPTTonesDropdownQuery= gql`
query ChatGPTTonesDropdownQuery($where: ChatGPTToneFilterInput) {  
  dropdown: ChatGPTTones(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;

/* CUSTOMS - START */


export const DepartmentsDropdownQuery = gql`
  query($where: DepartmentFilterInput) {  
    dropdown: Departments(where: $where) {
      items {
        value: ID
        label: Name
        ColourHex
      }
    }
  }
`;

export const PositionAgreementsDropdownQuery = gql`
  query($where: PositionAgreementFilterInput) {  
    dropdown: PositionAgreements(where: $where) {
      items {
        value: ID
        label: Title
        DepartmentID
      }
    }
  }
`;


export const BusinessProcessDiagramsDropdownQuery = gql`
  query($where: BusinessProcessDiagramFilterInput) {  
    dropdown: BusinessProcessDiagrams(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query($where: ProjectFilterInput, $order: [ProjectSortInput!]) {  
    dropdown: Projects(where: $where, order: $order) {
      items {
        value: ID
        label: ProjectName
        Color
      }
    }
  }
`;

export const BusinessProcessDiagramTypesDropdownQuery = gql`
  query($where: BusinessProcessDiagramTypeFilterInput) {  
    dropdown: BusinessProcessDiagramTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BusinessProcessDiagramStepTypesDropdownQuery = gql`
  query($where: BusinessProcessDiagramStepTypeFilterInput) {  
    dropdown: BusinessProcessDiagramStepTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const SprintsDropdownQuery = gql`
  query($where: SprintFilterInput) {  
    dropdown: Sprints(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const ChangeRequestsDropdownQuery = gql`
  query($where: ChangeRequestFilterInput) {  
    dropdown: ChangeRequests(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const FeaturesDropdownQuery = gql`
  query($where: FeatureFilterInput) {  
    dropdown: Features(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query($where: TaskFilterInput) {  
    dropdown: Tasks(where: $where) {
      items {
        value: ID
        label: TaskName
      }
    }
  }
`;

export const FeedbacksDropdownQuery = gql`
  query($featureID: Int, $where: FeedbackFilterInput) {  
    dropdown: Feedbacks(FeatureID: $featureID, where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const UserSchedsDropdownQuery = gql`
  query($weekNo: Int!) {  
    dropdown: UserSched(WeekNo: $weekNo) {
      TotalDevHoursUsed
      TotalDevWeekHours
      TotalPercentage
      TotalFEHoursUsed
      TotalFEHoursAvailable
      TotalFEPercentage
      TotalBEHoursUsed
      TotalBEHoursAvailable
      TotalBEPercentage
      TotalTesterHoursUsed
      TotalTesterHoursAvailable
      TotalTesterPercentage
      UserSched {
        HoursAvailable
        HoursUsed
        HoursWeekAvailable
        Percentage
        UserID
        User {
          Firstname
          Surname
          UserTypeIDs
          BaseLine
        }
      }
    }
  }
`;

export const FeatureStatusesDropdownQuery = gql`
  query {  
    dropdown: FeatureStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const FeedbackStatusesDropdownQuery = gql`
  query {  
    dropdown: FeedbackStatus {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const SprintStatusesDropdownQuery = gql`
  query {  
    dropdown: SprintStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const AffectedAreasDropdownQuery = gql`
  query {  
    dropdown: AffectedAreas {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const QuickTaskTypesDropdownQuery = gql`
  query {  
    dropdown: QuickTaskTypes
  }
`;

export const TaskStatusesDropdownQuery = gql`
  query($order: [TaskStatusSortInput!], $where: TaskStatusFilterInput) {  
    dropdown: TaskStatuses (order: $order, where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EnvironmentStatusesDropdownQuery = gql`
  query {  
    dropdown: EnvironmentStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;



export const EnvironmentModesdDropdownQuery = gql`
  query {  
    dropdown: EnvironmentModes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const AutoEmailCampaignStatusesDropdownQuery= gql`
  query {  
    dropdown: AutoEmailCampaignStatuses {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CRMMailboxSyncsDropdownQuery= gql`
  query($where: CRMMailboxSyncFilterInput) {  
    dropdown: CRMMailboxSyncs(where: $where) {
      items {
        value: ID
        label: EmailAddress
      }
    }
  }
`;

export const TargetCompaniesDropdownQuery= gql`
query($where: TargetCompanyFilterInput) {  
    dropdown: TargetCompanys(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const AutoEmailResponseTypeDropdownQuery= gql`
query($where: AutoEmailResponseTypeFilterInput) {  
    dropdown: AutoEmailResponseTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TaskTypeDropdownQuery= gql`
query($where: TaskTypeFilterInput) {  
    dropdown: TaskTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TestMatrixMethodsDropdownQuery = gql`
  query($where: TestMatrixMethodFilterInput) {  
    dropdown: TestMatrixMethods(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TestMatrixTypesDropdownQuery = gql`
  query($where: TestMatrixTypeFilterInput) {  
    dropdown: TestMatrixTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const TeamMembersDropdownQuery = gql`
  query(
    $where: TeamMemberFilterInput,
    $order: [TeamMemberSortInput!]
  ) {
    dropdown: TeamMembers(
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;
/* CUSTOMS - END */