export default {
    logo: {
        auth: '/media/logos/logo.png',
        splash: '/media/logos/logo.png',
        navbar: '/media/logos/logo.png'
    },
    background: '/media/bg/sc-home1-bg.png',
    brandName: 'TechAnywhere Ltd.',
    brandSite: '#',
    brandingFooter: false,
    website: process.env.REACT_APP_WEBSITE_URL!,
    allowOrganisationSelection: true,
    allowImpersonation: true,
    timeTracking: true,
    dateFormatDayLightSaving: false,
    defaultMasterTemplateID: 31,
    landingPath: '/dashboard',
    applicationAreaID: 1,
    metronic: {
        quickPanel: false,
        quickUser: true,
        quickActions: false,
        menu: false,
        aside: true,
        search: false,
        notifications: true,
        subheader: false
    },
    services: {
        cdn: process.env.REACT_APP_CDN_URL!,
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!,
        onesignal: process.env.REACT_APP_ONE_SIGNAL_ID!,
        getaddress: process.env.REACT_APP_GET_ADDRESS_API_KEY!,
        gqlBatchRequest: false,
        gqlBatchMax: 5,
        gqlBatchInterval: 20,
        gqlBatchDebounce: false
    },
    token: {
        name: 'taTK',
        expireday: 1, // 1 day
        domain: '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    notification: {
        soundEffectEnable: true,
        pathSoundEffect: '/media/audio/mixkit-attention-bell-ding.wav'
    },
    toaster: {
        duration: 3000,
        placement: "top",
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        simultaneousPreventionMessage: "You have been logged out because your User Account has logged in elsewhere.",
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }, 
    menuIcons: {
        // default: 'fa fa-layer-group'
    },
    menuPopups: {
    },
    filterGQLError: (count: number, error: any, operation: any) => {
        return true
    }
}