/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { QuickSearchOptionType } from "..";
import classNames from "classnames";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

interface QuickSearchResultProps {
    navigation: number;
    data: QuickSearchOptionType[] | null;
}

const QuickSearchResult: React.FunctionComponent<QuickSearchResultProps> = ({ navigation, data }) => {
    const history = useHistory()

    if (!data) {
        return (
            <div
                className="quick-search-result"
            />
        );
    }

    if (data.length === 0) {
        return (
        <div
            className="quick-search-wrapper scroll ps ps--active-y d-block"
        >
            <div className="quick-search-result">
                <div className="text-muted">No record found</div>
            </div>
        </div>
        );
    }

    return (
        <div
            style={{ maxHeight: "325px", overflow: "hidden" }}
            className="quick-search-wrapper scroll ps ps--active-y"
        >
            <div
                className="scroll overflow-auto"
                style={{ maxHeight: "325px", position: "relative" }}
            >
                <div className="quick-search-result">
                {/* begin: result */}
                    {data.map((item, index) => (
                        <div 
                            key={index}
                            className={classNames("d-flex align-items-center flex-grow-1 clickable bg-hover-secondary", {
                                "bg-secondary": navigation === index
                            })}
                            onClick={() => history.push(`/crm/companies/edit/${item.id}`)}
                        >
                            <div className="d-flex flex-column ml-3 py-2">
                                <p
                                className="font-weight-bold text-dark mb-1"
                                >
                                    {item.description || '-'}
                                </p>
                                {item.sub?.trim() && (
                                    <p className="font-size-sm font-weight-bold text-muted mb-0">
                                        {item.sub}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                {/* end: result */}
                </div>
            </div>
        </div>
    );
}

export default QuickSearchResult