import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SessionActionType, useOtherSession, useSession } from '@whitecobalt/tungsten/esm/components/Session';
import FormManager from '@whitecobalt/tungsten/esm/components/FormManager';
import Icon from '@whitecobalt/tungsten/esm/components/Icon';
import { FormEvent } from 'formydable';
import { useAuthAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthAPI';
import StopWatch, { StopWatchRef } from './StopWatch';
import classNames from 'classnames';
import './index.scss';
import worldTrigger from 'world-trigger'
import { useWorldTrigger } from '@whitecobalt/tungsten/esm/common/hooks/useWorldTrigger';
import SelectDropdown from '@whitecobalt/tungsten/esm/components/Fields/SelectDropdown';
import { ProjectsDropdownQuery } from '@services/query/DropdownQueries';
import DatePicker from '@whitecobalt/tungsten/esm/components/Fields/Date';
import Helptip from '@whitecobalt/tungsten/esm/components/Helptip';
import TaskField from './TaskField';
import { useAuthParamAPI } from '@whitecobalt/tungsten/esm/common/hooks/useAuthParamAPI';
import branding from '@config/branding';
import Toaster from '@whitecobalt/tungsten/esm/components/Toaster';
import { darkenColor } from '@whitecobalt/tungsten/esm/common/utils/colors';
import { useGlobalStoreSelector } from '@services/store/global';

const closeAllDropdown = () => {
    const timeTracker = document.getElementById('time-tracker')

    if(timeTracker) {
        timeTracker.querySelectorAll('.select-dropdown.show input').forEach((each: any) => {
            each.blur()
        })
    }
}

const TimeTracker: React.FunctionComponent = () => {
    const [{others}] = useSession()
    const [timeLogID, setTimelogID] = useGlobalStoreSelector('timeLogID')
    const stopWatchRef = useRef<StopWatchRef>(null)
    const [createMode, setCreatMode] = useState(false)
    const requestExistingTimeLog = useAuthAPI('/api/TimeLog', { method: "GET" })
    const requestCreate = useAuthAPI('/api/TimeLog/create')
    const requestStart = useAuthAPI('/api/TimeLog/start')
    const requestEnd = useAuthAPI('/api/TimeLog/end')
    const requestTaskTimeLog = useAuthParamAPI('/api/Task/timelog?id={id}', { method: 'POST' })
    const buttonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        closeAllDropdown()
        requestExistingTimeLog.call().then((response) => {
            setTimelogID(response.data.id)
        })
    }, [])

    useEffect(() => {
        if(!timeLogID) {
            const fetchExisting = () => {
                closeAllDropdown()
                requestExistingTimeLog.call().then((response) => {
                    setTimelogID(response.data.id)
                })
            }
            const cleanup = setInterval(fetchExisting, 60000)

            return () => clearInterval(cleanup)
        } else if(branding.services.env !== 'chrome') {
            const handle = () => {
                if (document.visibilityState === 'visible') {
                    requestExistingTimeLog.call().then((response) => {
                        setTimelogID(response.data.id)
                    })
                }
            }
            
            document.addEventListener("visibilitychange", handle);
    
            return () => document.removeEventListener("visibilitychange", handle);
        }
    }, [timeLogID])

    useWorldTrigger('time.log.sync', async () => {
        requestExistingTimeLog.call().then((response) => {
            setTimelogID(response.data.id)
        })
    }, [])

    useWorldTrigger('time.log.play', async ({ description, metaData1, metaData2, metaData3 }) => {
        setCreatMode(false)
        if(timeLogID) {
            await requestEnd.call({
                data: {
                    timeLogID
                }
            })
            .then(() => {
                stopWatchRef.current?.end()
                requestExistingTimeLog.setResponse(null)
                setTimelogID(null)
                worldTrigger.dispatchTrigger('time.log.reload')
                if(metaData2) {
                    requestTaskTimeLog.call({id: metaData2}).then(() => {
                        worldTrigger.dispatchTrigger('task.reload', metaData2)
                    })
                }
            })
        }

        requestExistingTimeLog.setResponse({ description, metaData1, metaData2, metaData3 })

        setTimeout(() => {
            buttonRef.current?.click()
        }, 300)
    }, [timeLogID])

    const handleSubmit = (event: FormEvent) => {
        const FORM_DATA = event.json()
        if(createMode === true) {

            if(event.isReady() === false) return;

            const logDate = FORM_DATA.logDate as Date
            const timeStart = FORM_DATA.timeStart?.split(':')
            const timeEnd = FORM_DATA.timeEnd?.split(':')
            const hoursTimeEnd = parseInt(timeEnd[0] || '0')
            const startDateTime = new Date(logDate?.getFullYear(), logDate?.getMonth(), logDate?.getDate(), parseInt(timeStart[0] || '0'), parseInt(timeStart[1] || '0'))
            const endDateTime = new Date(logDate?.getFullYear(), logDate?.getMonth(), logDate?.getDate() + (hoursTimeEnd === 0 ? 1 : 0), hoursTimeEnd, parseInt(timeEnd[1] || '0'))
            
            requestCreate.call({
                data: {
                    "description": FORM_DATA.description,
                    "startDateTime": startDateTime,
                    "endDateTime": endDateTime,
                    "metaData1": FORM_DATA.metaData1 || 0,
                    "metaData2": FORM_DATA.metaData2 || null,
                    "metaData3": FORM_DATA.metaData3 || null,
                    "metaData4": null
                  }
            })
            .then((response) => {
                if(response.data?.success === true) {
                    worldTrigger.dispatchTrigger('time.log.reload')
                    event.resetForm()
                    event.setFieldValues({
                        logDate: FORM_DATA.logDate,
                        timeStart: FORM_DATA.timeStart,
                        timeEnd: FORM_DATA.timeEnd,
                    })
                    
                    if(FORM_DATA.metaData2) {
                        requestTaskTimeLog.call({id: FORM_DATA.metaData2}).then(() => {
                            worldTrigger.dispatchTrigger('task.reload')
                        })
                    }
                } else {
                    let errorMessage = response.data.errors?.[0]?.errorMessage || response.data.message
                    if(errorMessage === 'Must be after Start') {
                        errorMessage = 'End date must be after start date'
                    }
                    Toaster.fail(errorMessage)
                }
            })
        } else {
            if(!timeLogID) {
                if(event.isReady() === false) return;
    
                requestStart.call({
                    data: {
                        "description": FORM_DATA.description,
                        "metaData1": FORM_DATA.metaData1 || null,
                        "metaData2": FORM_DATA.metaData2 || null,
                        "metaData3": FORM_DATA.metaData3 || null,
                        "metaData4": null
                    }
                })
                .then((response) => {
                    if(response.data.success === true) {
                        setTimelogID(response.data.id)
                        stopWatchRef.current?.start()
                    }
                })
            } else {
                requestEnd.call({
                    data: {
                        timeLogID
                    }
                })
                .then((response) => {
                    if(response.data.success === true) {
                        stopWatchRef.current?.end()
                        requestExistingTimeLog.setResponse(null)
                        setTimelogID(null)
                        worldTrigger.dispatchTrigger('time.log.reload')
                        event.resetForm()
                        if(FORM_DATA.metaData2) {
                            requestTaskTimeLog.call({id: FORM_DATA.metaData2}).then(() => {
                                worldTrigger.dispatchTrigger('task.reload')
                            })
                        }
                    }
                })
            }
        }
    }

    const dateNow = useMemo(() => {
        const todayRaw = new Date()

        const today = new Date(todayRaw.getFullYear(),  todayRaw.getMonth(), todayRaw.getDate())

        const hours = todayRaw.getHours()
        const minutes = todayRaw.getMinutes()
        return {
            today,
            timeNow: `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:00`
        }
    }, [createMode])

    return (
        <FormManager onSubmit={handleSubmit} className="w-100">
            <div id="time-tracker" className="mt-3 mt-lg-0">
                <div className="d-flex justify-content-between w-100">
                    <FormManager.Input 
                        type="text" 
                        id="track-desc"
                        name="description" 
                        placeholder="What are you working on?"
                        stripped
                        rules="required"
                        className="task-description"
                        value={requestExistingTimeLog.response?.description}
                        length={100}
                        disabled={!!timeLogID} />
                    {branding.services.env !== 'chrome' && (
                        <>
                            <FormManager.Input 
                                as={SelectDropdown.Graph}
                                gql={ProjectsDropdownQuery}
                                fetchPolicy="cache-first"
                                // variables={{
                                //     where: {
                                //         OrganisationID: {
                                //             equals: others?.organisationID,
                                //         }
                                //     }
                                // }}
                                pollInterval={1000 * 35}
                                id="track-project"
                                name="metaData1" 
                                placeholder="Project"
                                stripped
                                rules="required_number"
                                value={requestExistingTimeLog.response?.metaData1}
                                disabled={!!timeLogID || requestExistingTimeLog.loading}
                                clearable={false}
                                manageOptions={(each: any) => {
                                    const color = each.Color ? darkenColor(each.Color, 1) : undefined
                                    return {
                                        ...each, 
                                        labelNode: (
                                            <div className="d-flex align-items-center" style={{color: color}}>
                                                <div className="bilot-bilot mr-2" style={{backgroundColor: each.Color || '#555'}}/>{each.label}
                                            </div>
                                        )
                                    }
                                }} />
                            <div className="ml-2" style={{width: '100%', maxWidth: 200}}>
                                <TaskField 
                                    id="track-task"
                                    stripped={true}
                                    value={requestExistingTimeLog.response?.metaData2}
                                    loading={!!timeLogID || requestExistingTimeLog.loading}
                                    />
                            </div>
                            {/* <Helptip id="metaData3" description="Investigation?" overlayTriggerProps={{placement: "bottom"}}>
                                <div className="d-flex align-items-center ml-2">
                                    <FormManager.Boolean 
                                        id="track-spec"
                                        name="metaData3" 
                                        formGroupClassName="mb-0"
                                        checked={requestExistingTimeLog.response?.metaData3}
                                        disabled={!!timeLogID || requestExistingTimeLog.loading} />
                                </div>
                            </Helptip> */}
                        </>
                    )}
                </div>
                <div className="time-tracker-actions">
                    {createMode ? (
                        <div className="time-tracker-time-range">
                            <FormManager.Input 
                                as={DatePicker}
                                name="logDate" 
                                placeholder="01/11/1997"
                                stripped
                                value={dateNow.today}
                                disabled={!!timeLogID} />
                            <FormManager.Input 
                                type="time"
                                name="timeStart" 
                                stripped
                                rules="required"
                                className="custom-time-field"
                                value={dateNow.timeNow}
                                disabled={!!timeLogID} />
                            <FormManager.Input 
                                type="time"
                                name="timeEnd" 
                                stripped
                                rules="required"
                                className="custom-time-field"
                                value={dateNow.timeNow}
                                disabled={!!timeLogID} />
                        </div>
                    ) : (
                        <StopWatch ref={stopWatchRef} defaultActive={!!timeLogID} defaultSeconds={Math.floor((requestExistingTimeLog.response?.hours || 0) * 60 * 60)} />
                    )}
                    <button ref={buttonRef} type="submit" className={classNames("btn btn-sm btn-rounded", null, `btn-${!!timeLogID ? 'danger' : 'primary'}`)}>
                        <Icon name={createMode ? "plus" : timeLogID ? "stop" : "play"} className="pr-0" style={!createMode && !timeLogID ? {position: 'relative', left: 2 } : undefined} />
                    </button>
                    {branding.services.env !== 'chrome' && (
                        <div className="time-tracker-modes">
                            <button type="button" className={classNames("btn btn-sm btn-rounded btn-mini btn-primary mb-1")} disabled={createMode === false} onClick={() => setCreatMode(false)}>
                                <Icon name="play" className="pr-0" />
                            </button>
                            <button type="button" className={classNames("btn btn-sm btn-rounded btn-mini btn-primary")} disabled={createMode === true || !!timeLogID} onClick={() => setCreatMode(true)}>
                                <Icon name="plus" className="pr-0" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {branding.services.env === 'chrome' && (
                <div className="d-flex justify-content-between mt-2">
                    <FormManager.Input 
                        as={SelectDropdown.Graph}
                        gql={ProjectsDropdownQuery}
                        // variables={{
                        //     where: {
                        //         OrganisationID: {
                        //             equals: others?.organisationID,
                        //         }
                        //     }
                        // }}
                        fetchPolicy="cache-first"
                        name="metaData1" 
                        placeholder="Project"
                        stripped
                        rules="required_number"
                        value={requestExistingTimeLog.response?.metaData1}
                        disabled={!!timeLogID || requestExistingTimeLog.loading}
                        manageOptions={(each: any) => {
                            const color = each.Color ? darkenColor(each.Color, 1) : undefined
                            return {
                                ...each, 
                                labelNode: (
                                    <div className="d-flex align-items-center" style={{color: color}}>
                                        <div className="bilot-bilot mr-2" style={{backgroundColor: each.Color || '#555'}}/>{each.label}
                                    </div>
                                )
                            }
                        }} />
                    <div className="ml-2" style={{width: '100%', maxWidth: 200}}>
                        <TaskField 
                            stripped={true}
                            value={requestExistingTimeLog.response?.metaData2}
                            loading={!!timeLogID || requestExistingTimeLog.loading}
                            />
                    </div>
                    {/* <Helptip id="metaData3" description="Investigation?" overlayTriggerProps={{placement: "bottom"}}>
                        <div className="d-flex align-items-center justify-content-center ml-2" style={{width: '100%', maxWidth: 50}}>
                            <FormManager.Boolean 
                                name="metaData3" 
                                formGroupClassName="mb-0"
                                checked={requestExistingTimeLog.response?.metaData3}
                                disabled={!!timeLogID || requestExistingTimeLog.loading} />
                        </div>
                    </Helptip> */}
                </div>
            )}
        </FormManager>
    )
}

export default TimeTracker