import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI";
import Icon from "@whitecobalt/tungsten/esm/components/Icon";
import React from 'react'

export type QuickSearchOptionType =  {
    id: number;
    description: string;
    sub?: string;
}

export const QUICK_SEARCH_TIMEOUT = 500

export const QUICK_SEARCH_TITLE = "Quick Search [CTRL + F]"

export const QUICK_SEARCH_ICON = (
    <Icon name="search"/>
)

export const QUICK_SEARCH_TOGGLE_ICON = (
    <Icon className="text-primary" name="search"/>
)

export const useQuickSearch = () => {
    const request = useAuthAPI('/api/TargetCompany/search')

    return (search: string): Promise<QuickSearchOptionType[]> => request.call({
        data: {
            "prefix": search,
            "maxResults": 10
        }
    }).then((response) => response.data.companies?.map((item: any) => ({
        id: item.id,
        description: item.companyName,
        sub: ''
    })))
}
